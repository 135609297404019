<template>
  <v-dialog
    v-model="input"
    width="1000"
    persistent
  >
    <v-card>
      <v-card-title
        class="headline"
        primary-title
        v-html="content.title"
      />
      <v-alert
        :value="true"
        :color="content.color"
        v-html="content.body"
      />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          flat
          @click="agreePdpa(false)"
        >
          Disagree
        </v-btn>
        <v-btn
          flat
          @click="agreePdpa(true)"
        >
          Agree
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="10000"
      :color="snackbarColor"
      top
    >
      {{ snackbarText }}
      <v-btn
        flat
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { createPostParams } from '@/rest'

export default {
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    content: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      input: null,
      agree: null,
      snackbar: false,
      snackbarText: null,
      snackbarColor: 'success',
    }
  },
  mounted: function () {
    this.input = this.value
  },
  methods: {
    close: function () {
      this.input = false
      this.$emit('input', false)
    },

    async agreePdpa (val) {
      if (val) {
        this.agree = 'agree'
      } else {
        this.agree = 'disagree'
      }

      if (!val) {
        this.snackbarText = 'You must agree to the Terms & conditions before proceed.'
        this.snackbarColor = 'error'
        this.snackbar = true
        this.input = true
        return
      }
      console.log(this.agree)
      const params = createPostParams({
        agree: this.agree,
      })
      await this.$rest.post('postJomBantuTnc.php', params)
      this.input = false
      this.$emit('input', false)
    },
  },
}
</script>
